import { createFeature, createReducer, createSelector, on } from '@ngrx/store';

import { WorkflowMemberNode, WorkflowNode } from 'src/app/shared/components/workflow';
import { WorkflowLink } from 'src/app/shared/components/workflow/utils/models';

import { WorkflowApiActions, WorkflowUIActions } from './workflow.actions';

export const workflowsFeatureKey = 'workflow';

export interface WorkflowState {
  isLoading: boolean;
  workflow: WorkflowNode[];
  selectedWorkflowNode: WorkflowNode | null;
  selectedMemberNode: WorkflowMemberNode | null;
  selectedLink: WorkflowLink | null;
  parsingErrors: string[];
  updatesAvailable: number;
}

export const initialState: WorkflowState = {
  isLoading: true,
  workflow: [],
  selectedWorkflowNode: null,
  selectedMemberNode: null,
  selectedLink: null,
  parsingErrors: [],
  updatesAvailable: 0
};

export const workflowReducer = createReducer(
  initialState,
  on(WorkflowUIActions.findAllTriggered, (state): WorkflowState => {
    return { ...state, isLoading: true };
  }),
  on(WorkflowApiActions.findAllSucceeded, (state, { items, errors }): WorkflowState => {
    return { ...state, workflow: items, parsingErrors: errors, isLoading: false };
  }),
  on(WorkflowApiActions.requestFailed, (state): WorkflowState => {
    return { ...state, workflow: [], parsingErrors: ['Request failed'], isLoading: false };
  }),
  on(WorkflowUIActions.workflowSelected, (state, { workflow }): WorkflowState => {
    return { ...state, selectedWorkflowNode: workflow, selectedLink: null, selectedMemberNode: null };
  }),
  on(WorkflowUIActions.workflowMemberSelected, (state, { member }): WorkflowState => {
    return { ...state, selectedWorkflowNode: null, selectedLink: null, selectedMemberNode: member };
  }),
  on(WorkflowUIActions.workflowLinkSelected, (state): WorkflowState => {
    return { ...state, selectedWorkflowNode: null, selectedLink: null, selectedMemberNode: null };
  })
);

export const workflowFeature = createFeature({
  name: workflowsFeatureKey,
  reducer: workflowReducer,
  extraSelectors: ({ selectSelectedWorkflowNode, selectSelectedMemberNode, selectSelectedLink }) => ({
    selectSelectedParts: createSelector(
      selectSelectedWorkflowNode,
      selectSelectedMemberNode,
      selectSelectedLink,
      (workflow, member, link) => ({
        workflow,
        member,
        link
      })
    )
  })
});

export const { selectSelectedWorkflowNode, selectSelectedMemberNode, selectSelectedLink, selectSelectedParts } = workflowFeature;
