import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { GraphQLError } from 'graphql';

import { WorkflowMemberNode, WorkflowNode } from 'src/app/shared/components/workflow';
import { WorkflowLink } from 'src/app/shared/components/workflow/utils/models';

export const WorkflowUIActions = createActionGroup({
  source: 'Workflow UI',
  events: {
    FindAllTriggered: emptyProps(),
    WorkflowSelected: props<{ workflow: WorkflowNode | null }>(),
    WorkflowMemberSelected: props<{ member: WorkflowMemberNode }>(),
    WorkflowLinkSelected: props<{ link: WorkflowLink }>()
  }
});

export const WorkflowApiActions = createActionGroup({
  source: 'Workflow API',
  events: {
    FindAllSucceeded: props<{ items: WorkflowNode[]; errors: string[] }>(),
    RequestFailed: props<{ errors: readonly GraphQLError[] }>()
  }
});
